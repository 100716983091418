.abbvieWrap {
  display: flex;
}

aside {
  margin: 5px;
  border-radius: 5px;
  border: 2px solid white;
  background-color: #b7daea;
  height: 200px;
  justify-content: space-between;
  align-items: flex-start;
}

.selected {
  border: 1px solid grey;
}

.react-flow__edge-path-selector:hover {
  cursor: pointer;
}
.react-flow__edge-path-selector:hover + .react-flow__edge-path,
.react-flow__edge-path:hover {
  stroke: #ff6600;
  cursor: pointer;
}
.react-flow__edge-path-selector {
  fill: none;
  stroke: transparent;
  stroke-width: 28;
}

.react-flow__edges {
  z-index: 2;
}

.react-flow__nodes {
  z-index: 2 !important;
}

.colorPicker {
  border: 1px solid #c8c8c8;
  border-radius: 4px;
  margin-top: 15px;
  height: 41px;
  display: flex;
  width: 100%;
  align-items: center;
  color: #666;
  font-size: 12px;
  justify-content: center;
  background: #e8e8e8;
}

.ImagePicker {
  border: 1px solid #c8c8c8;
  border-radius: 4px;
  margin-top: 15px;
  padding: 18px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  position: relative;
}

.ImagePicker label {
  position: absolute;
  bottom: -17px;
}

.propsPlaceholder {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9d9d9d;
  min-height: 640px;
  font-weight: bold;
  font-size: 0.7em;
}

.cardField {
  color: #0f5aff;
}
