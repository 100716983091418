/* body {
  background-color: #341cac !important;
} */

main {
  background-color: #0041d0;
  /* height: calc(100vh - 70px); */
  display: flex;
  /* position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 70px; */
  min-height: 900px;
  padding-bottom: 50px;
}
.clear {
  clear: both;
}
.delete {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: #666;
  padding: 4px 6px;
  border: none;
  color: #fff;
  border-radius: 4px;
}

.card {
  width: 280px;
  display: block;
  margin: 60px auto;
  /* box-shadow: 10px 5px 40px 20px #2d1896; */
  transition: 0.25s;
}
.card:hover {
  box-shadow: 10px 5px 40px 20px #271580;
  cursor: pointer;
}
.card:hover .button-primary {
  /* transform: translate(10px, 0); */
}
.card-header {
  height: 150px;
  width: 100%;
  padding: 15px;
  width: 100%;
  background-size: cover;
  color: #fff;
}
.card-header--title {
  text-transform: uppercase;
  margin: 0;
}
.card-body {
  padding: 15px;
  background-color: #00013a;
  width: 100%;
  position: relative;
}

h2 {
  color: white;
  font-size: 16px;
}
.date {
  font-size: 11px;
  font-weight: 600;
  color: grey;
}
.body-content {
  padding-bottom: 40px;
  font-size: 13px;
  line-height: 1.8;
}
.button-primary {
  border: none;
  box-shadow: none;
  font-family: inherit;
  background-color: transparent;
  color: #999;
  font-size: 10px;
  /* transition: 0.25s; */
  padding: 0;
}
.button-primary i {
  padding-right: 4px;
}
.designer-link {
  color: #fff;
  text-align: center;
  fon-size: 12px;
  text-decoration: none;
  display: block;
}

.app-title {
  display: inline-block;
  width: 100%;
  margin: 80px 0 0 0;
  float: left;
  position: relative;
  color: #fff;
}
@media (min-width: 768px) {
  .app-title {
    width: 20%;
    margin: 200px 0 0 0;
  }
}
.app-title h1 {
  font-size: 52px;
  margin: 0;
  line-height: 60px;
}
.app-title p {
  font-weight: 200;
  opacity: 0.5;
}
.app-title-content {
  position: relative;
  top: 50%;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
}
@media (min-width: 768px) {
  .app-title-content {
    width: auto;
    position: absolute;
    text-align: left;
  }
}
.app-card-list {
  display: inline-block;
  width: 100%;
  float: right;
  margin: 0;
  min-height: 75vh;
  white-space: normal;
  overflow-x: scroll;
  margin-top: -70px;
}
.app-card-list::-webkit-scrollbar {
  display: none;
}
@media (min-width: 768px) {
  .app-card-list {
    width: 80%;
    white-space: nowrap;
    margin-top: 0;
  }
}
.app-card-list .card {
  margin: 60px 30px;
  display: inline-block;
}
.app-card-list .card .card-body {
  white-space: normal;
}
.designer-link {
  display: inline-block;
  padding-top: 40px;
}
